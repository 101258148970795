import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";

import { devicesSlice } from "../app/modules/IOT/_redux/device/devicesSlice";
import { logicSlice } from "../app/modules/IOT/_redux/logic";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  devices: devicesSlice.reducer,
  logic: logicSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
