import axios from "axios";

export const URL = "device";

// READ
export function getAllDevices() {
  return axios.get(URL);
}

// READ
export function devicePower() {
  return axios.get(URL);
}

// Update
export function updateSecurityStatus(id, notifications, securityStatus) {
  const params = { notifications, securityStatus };
  return axios.put(`${URL}/${id}/notifications`, params);
}

export function all() {
  return axios.get(`${URL}/list`);
}