import {createSlice} from "@reduxjs/toolkit";

const initialDevicesState = {
    listLoading: false,
    actionsLoading: false,
    entities: null,
    entity: null,
    lastError: null,
    timer: null,
    timerCreate: false,
    timerIndex: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const devicesSlice = createSlice({
  name: "devices",
  initialState: initialDevicesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findDevices
    devicesFetched: (state, action) => {
        const data = action.payload;
        state.listLoading = false;
        state.error = null;
        state.entities = data;
    },
    // findDevices
    deviceSecurityStatusUpdated: (state, action) => {
        const data = action.payload;
        state.listLoading = false;
        state.error = null;
        state.entities = state.entities.map(entity => {
            if (entity.id === data.id) {
                entity.extra.securityStatus = data.status;
            }
            return entity;
        });
    },
      // setCurrentDevice
      setCurrentDevice: (state, action) => {
          const data = action.payload;
          state.entity = data.device;
      },
       // setCurrentDevice
       setCurrentTimer: (state, action) => {
          state.timer = action.payload.timer;
          state.timerCreate = action.payload.create;
          state.timerIndex = action.payload.index;
      },
  }
});
