import React from 'react';
import * as mqtt from 'mqtt';
import { search } from './JsonHelper';

const MqttContext = React.createContext();

const RESET_PAYLOAD = {
    "Arm":0,
    "Time":"00:00",
    "Window":0,
    "Days":"0000000",
    "Repeat":0,
    "Output":0,
    "Action":0
};

export class MqttProvider extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            client: null,
            sub: (...topics) => {
                if (!this.state.client) return false;
                for (const topic of topics) this.state.client.subscribe(topic);
                return true;
            },
            pub: (topic, payload) => {
                if (!this.state.client) return false;
                this.state.client.publish(topic, payload);
                return true;
            },
            isOnline: topic => {
                const status = this.state[`tele/${topic}/LWT`] || '';
                return status.toLowerCase() === 'online';
            },
            isPowerOn: topic => {
                const power1 = this.state[`stat/${topic}/POWER`];
                if (power1) return power1  === 'ON'

                const status11  = this.state[`stat/${topic}/STATUS11`] || {};
                const power2 = (status11 ? search(status11, 'power') : 'off') === 'on';
                return power2 || false;
            },
            powerDetails: topic => {
                const data  = this.state[`stat/${topic}/STATUS8`] || '';
                return {
                    voltage: data ? search(data, 'voltage') : 0,
                    current: data ? search(data, 'current') : 0,
                    power: data ? search(data, 'power') : 0,
                    apparent: data ? search(data, 'apparentpower') : 0,
                    reactive: data ? search(data, 'reactivepower') : 0,
                    frequency: data ? search(data, 'frequency') : 0,
                    factor: data ? search(data, 'factor') : 0,
                    total: data ? search(data, 'total') : 0,
                    today: data ? search(data, 'today') : 0,
                    yesterday: data ? search(data, 'yesterday') : 0
                };
            },
            timers: topic => {
                const data  = this.state[`stat/${topic}/RESULT`] || {};
                const timers = [];
                for(const key of Object.keys(data)){
                    if(key.includes('timer') && !key.includes('timers')){
                        const timerIndex = Number.parseInt(key.replace('timer', ''));
                        timers[timerIndex] = data[key];
                    }
                }
                return timers;
            },
            resetTimer: (topic, index) => {
                this.state.client.publish( `cmnd/${topic}/Timer${index}`, JSON.stringify(RESET_PAYLOAD));
            },
            setTimer: (topic, index, data) => {
                this.state.client.publish( `cmnd/${topic}/Timer${index}`, JSON.stringify(data));
            }
        };
    }

    componentDidMount(){
        const client = mqtt.connect({
            host: 'mqtt.dodotik.com',
            protocol: 'wss',
            port: 9002,
            username: 'DODOTIK',
            password: 'DoDoTiK@2019',
            clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
            clean: true,
            path: '/mqtt'
        });
        this.setState({ client });

        client.on('connect',  () => {
            // message is Buffer
            console.log('connect');
        })
        
        client.on('message', (topic, message) => {
            // message is Buffer
            try{
                const json = JSON.parse(message.toString().toLowerCase());
                if(json.timers1 || json.timers2 || json.timers3 || json.timers4){
                    const timers = json[Object.keys(json)[0]];
                    this.setState({[topic]: Object.assign(this.state[topic] || {}, timers)});
                } else {
                    this.setState({[topic]: Object.assign(this.state[topic] || {}, json)});
                }
            } catch {
                this.setState({[topic]: message.toString()})
            }
            // console.log(message.toString());
        })

        this.setState({ client, subscribe: this.subscribe });
    }


    render() {
        return (
            <MqttContext.Provider value={this.state}>
                {this.props.children}
            </MqttContext.Provider>
        );
    }
}


export function withMqttContext(Component) {
    class ComponentWithMqtt extends React.Component{
        render(){
            return (
                <MqttContext.Consumer>
                    { mqtt =>  <Component {...this.props} mqtt={mqtt} />}
                </MqttContext.Consumer>
            );
        }
    }
    return ComponentWithMqtt;
}
