import React from 'react';
import { Badge, OverlayTrigger, Tooltip, Table } from 'react-bootstrap';
import { withMqttContext } from 'app/MqttProvider';

const PowerDetails = ({ mqtt, topic }) => {

    const { voltage, current, power, reactive, apparent, frequency, factor, today, yesterday, total } = mqtt.powerDetails(topic);

    return (
        <Table   hover>
            <tbody>
                <tr className="text-left">
                    <td>Voltage</td>
                    <td>{ voltage || 0 } VA</td>
                </tr>
                <tr className="text-left">
                    <td>Current</td>
                    <td>{ current || 0 } A</td>
                </tr>
                <tr className="text-left">
                    <td>Power</td>
                    <td>{ power || 0 } W</td>
                </tr>
                <tr className="text-left">
                    <td>Apparent Power</td>
                    <td>{ apparent || 0 } VA</td>
                </tr>
                <tr className="text-left">
                    <td>Reactive Power</td>
                    <td>{ reactive || 0 } VAr</td>
                </tr>
                <tr className="text-left">
                    <td>Power Factor</td>
                    <td>{ factor || 0 }</td>
                </tr>
                <tr className="text-left">
                    <td>Frequency</td>
                    <td>{ frequency } Hz</td>
                </tr>
                <tr className="text-left">
                    <td>Energy Today </td>
                    <td>{ today || 0 } kWh</td>
                </tr>
                <tr className="text-left">
                    <td>Energy Yesterday</td>
                    <td>{ yesterday || 0 } kWh</td>
                </tr>
                <tr className="text-left">
                    <td>Energy Total</td>
                    <td>{ total || 0 } kWh</td>
                </tr>
            </tbody>
        </Table>
    );
}

export default withMqttContext(PowerDetails);
