import React, { useEffect, useState } from 'react';
import { withMqttContext } from 'app/MqttProvider';
import { Signal, DeviceImage } from './../components';
import { Image } from 'react-bootstrap';
import * as actions from 'app/modules/IOT/_redux/device/devicesActions';
import { useDispatch } from "react-redux";
import PowerDetails from '../PowerDetails';
import  IOSSwitch from './../components/IOSSwitch';
import { toAbsoluteUrl } from '_metronic/_helpers';

const ApplianceItem = ({ mqtt, item, topic, openDetailsPanel }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        subscribe();
        publish();
    }, []);

    const subscribe = () => {
        const result = mqtt.sub(
            `tele/${topic}/LWT`,
            `stat/${topic}/STATUS8`,
            `stat/${topic}/POWER`,
            `stat/${topic}/RESULT`,
        );
        if (!result) setTimeout(subscribe, 3000)
    };

    const publish = () => {
        mqtt.pub(`cmnd/${topic}/Status`, '8');
        setTimeout(() => publish(), 30000);
    };

    const switchChange = e => {
        mqtt.pub(`cmnd/${topic}/POWER`, e.target.checked ? 'ON' : 'OFF');
    };

    const openDetails = e => {
        mqtt.pub(`cmnd/${topic}/Timers`, ' ');
        dispatch(actions.setCurrentDevice(item));
        openDetailsPanel();
    }

    const renderSwitch = () => <IOSSwitch checked={mqtt.isPowerOn(topic)} onChange={switchChange} />

        // <span className="switch switch-sm switch-primary mr-4">
        //     <label>
        //         <input
        //             type="checkbox"
        //             name="power"
        //             checked={ mqtt.isPowerOn(topic) }
        //             onChange={switchChange}/>
        //         <span></span>
        //     </label>
        // </span>
    

    return (
        <div className="d-flex align-items-center mb-10">
            <div onClick={openDetails}>
                <DeviceImage icon={item.icon} />
            </div>
            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                <a href className="text-dark text-hover-primary mb-1 font-size-lg" onClick={openDetails}>
                    { item.name }
                </a>
                <span className="text-muted">
                    <PowerDetails data={mqtt.powerDetails(topic)} openDetails={openDetails} />
                    <Image src={toAbsoluteUrl(`/media/dodo-icons/alarm.png`)} width={30} rounded role="button" onClick={openDetails}/>
                </span>
            </div>
            { renderSwitch() }
            <Signal topic={topic} />
        </div>
    );

};

export default withMqttContext(ApplianceItem);
