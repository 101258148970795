import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { search } from '../../JsonHelper';
import { withMqttContext } from 'app/MqttProvider';
import { toAbsoluteUrl } from '_metronic/_helpers';

const Signal = (props) => {

    useEffect(() => {
        subscribe();
        publish();
    }, []);

    const subscribe = () => {
        const status = props.mqtt.sub(`stat/${props.topic}/STATUS11`);
        if (!status) setTimeout(subscribe, 3000);
    }

    const publish = () => {
        props.mqtt.pub(`cmnd/${props.topic}/Status`, '11');
        setTimeout(() => publish(), 10000);
    }

    const icon = type => {
        const data = props.mqtt[`stat/${props.topic}/STATUS11`]  || '';
        const rssi = data ? search(data, 'rssi') : -1;

        let status = 'none';
        if (rssi < 0) status = 'none';
        else if (rssi < 25) status = 'poor';
        else if (rssi < 50) status = 'moderate';
        else if (rssi < 75) status = 'good';
        else status = 'full';

        return toAbsoluteUrl(`/media/dodo-icons/${status}.png`);
    }

    return <Image src={icon()} rounded width={25} height={25} />;

};

export default withMqttContext(Signal);
