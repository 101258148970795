/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, {useEffect, useState} from "react";
import { Tab, Nav } from "react-bootstrap";
import '@radial-color-picker/react-color-picker/dist/react-color-picker.min.css';
import './style.css';
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import RgbContent from './RgbContent';
import TimersContent from './TimersContent';
import * as actions from '../../../_redux/device/devicesActions';
import PowerDetails from './PowerDetails';

const DevicePanel = () => {

    const dispatch = useDispatch();

    const [selectedTab, setSelectedTab] = useState(1);
    const setTab = tab => setSelectedTab(tab);

    // Getting curret state of devices list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.devices }),
        shallowEqual
    );

    const { entity } = currentState;

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
    });


    const isRgb = () => entity && entity.data && entity.data.type === 'rgb';
    const isPower = () => entity && entity.blockName === 'appliance';
    const isMonitor = () => entity && entity.blockName === 'appliance' && (entity.data && entity.data.type === 'monitor');

    const isSelected = (type) => {
        if(type === 'rgb')
            return selectedTab == 1 || selectedTab === "rgb";
        if(type === 'power')
            return (selectedTab == 1 || selectedTab === "power" || (entity.data || {}).type === 'monitor');
        if(type === 'timers')
            return selectedTab === "timers";
        return false;
    }

    const showTab = (type) => {
        if(type === 'rgb')
            return isRgb();
        if(type === 'power')
            return isPower();
        if(type === 'timers')
            return !isMonitor();
        return false;
    }

    return (
        <div id="kt_quick_panel" className="offcanvas offcanvas-right pt-5 pb-10">
            <Tab.Container
                defaultActiveKey={selectedTab}
            >
                {/*begin::Header*/}
                <div
                    className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
                    <Nav
                        onSelect={setTab}
                        as="ul"
                        role="tablist"
                        className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
                    >
                        {
                            showTab('rgb') &&
                            <Nav.Item as="li">
                                <Nav.Link eventKey="rgb" className={`nav-link ${isSelected('rgb') ? "active" : "" }`}>
                                    { entity ? entity.name : '' }
                                </Nav.Link>
                            </Nav.Item>
                        }

                        {
                            showTab('power') &&
                            <Nav.Item as="li">
                                <Nav.Link 
                                    eventKey="power"
                                    className={`nav-link ${isSelected('power') ? 'active' : '' }`}
                                >
                                    { entity ? entity.name : '' }
                                </Nav.Link>
                            </Nav.Item>
                        }
                        {
                            showTab('timers') &&
                            <Nav.Item className="nav-item" as="li">
                                <Nav.Link eventKey="timers" className={`nav-link ${isSelected('timers') ? "active" : ""}`}>
                                    Timers
                                </Nav.Link>
                            </Nav.Item>
                        }

                    </Nav>

                    <div className="offcanvas-close mt-n1 pr-5" style={{position: "absolute", top: "15px", right: "10px"}}>
                        <a
                            href="#"
                            className="btn btn-xs btn-icon btn-light btn-hover-primary"
                            id="kt_quick_panel_close"
                            onClick={() => {
                                setTab(1);
                                dispatch(actions.setCurrentDevice(null))
                            }
                        }
                        >
                            <i className="ki ki-close icon-xs text-muted"></i>
                        </a>
                    </div>
                </div>
                {/*end::Header*/}

                {/*begin::Content*/}
                <div className="offcanvas-content px-10">
                    <div className="tab-content">
                        <div 
                            id="kt_quick_panel_rgb" role="tabpanel" 
                            className={`tab-pane fade pt-2 pr-5 mr-n5 scroll ps ${(isRgb() && ( selectedTab == 1 || selectedTab === "rgb")) ? "active show" : ""}`}
                        >
                            { isRgb() && <RgbContent /> }
                        </div>
                        <div 
                            id="kt_quick_panel_power"
                            role="tabpanel"
                            className={`tab-pane fade pt-2 pr-5 mr-n5 scroll ps ${(isPower() && ( selectedTab == 1 || selectedTab === "power"|| (entity.data || {}).type === 'monitor')) ? "active show" : ""}`}
                        >
                            { 
                                isPower() && <PowerDetails topic={entity.topic} />
                            }
                        </div>
                        <div 
                            id="kt_quick_panel_timers"
                            role="tabpanel"
                            className={`tab-pane fade pt-2 pr-5 mr-n5 scroll ps ${(selectedTab === "timers") ? "active show" : ""}`}
                        >
                            { <TimersContent /> }
                        </div>
                    </div>
                </div>
                {/*end::Content*/}
            </Tab.Container>

        </div>
    );
}
export default DevicePanel;
