import { SensorsWidget } from './sensor/SensorsWidget';
import { AppliancesWidget } from './appliance/AppliancesWidget';
import { LightsWidget } from './light/LightsWidget';
import { PowerWidget } from './power/PowerWidget';
import SecurityWidget from './security/SecurityWidget';
import { TimerList } from './timer/TimerList';

export {
    SensorsWidget, AppliancesWidget, LightsWidget, PowerWidget, SecurityWidget, TimerList
};
