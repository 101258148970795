import * as requestFromServer from "./devicesCrud";
import {devicesSlice, callTypes} from "./devicesSlice";

const {actions} = devicesSlice;

export const getAllDevices = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getAllDevices()
        .then(response => dispatch(actions.devicesFetched(response.data)))
        .catch(error => {
            error.clientMessage = "Can't find devices";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateSecurityStatus = (id, notification, status) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .updateSecurityStatus(id, notification, status)
        .then(() => {
            dispatch(actions.deviceSecurityStatusUpdated({ id, notification, status }));
        })
        .catch(error => {
            error.clientMessage = "Can't update device security status";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const setCurrentDevice = (device) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return dispatch(actions.setCurrentDevice({ device }));
};

export const setCurrentTimer = (index, timer,create = false) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return dispatch(actions.setCurrentTimer({ index, timer, create }));
};
