import React, {Suspense, lazy} from "react";
import { Switch } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';

const IOTPage = lazy(() =>
    import("./modules/IOT/pages/IOTPage")
);

export default function BasePage() {

    // useEffect(() => {
    //   console.log('Base page');
    // }, [])
    // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense
            fallback={<LayoutSplashScreen/>}
        >
            <Switch>
                <ContentRoute path='/' component={IOTPage} />
            </Switch>
        </Suspense>
    );

}
