import React, { useEffect } from 'react';
import { withMqttContext } from 'app/MqttProvider';
import { Signal, DeviceImage } from './../components';
import PowerDetails from '../PowerDetails';
import { useDispatch } from "react-redux";
import * as actions from './../../../../_redux/device/devicesActions';

const PowerItem = ({ mqtt, item, topic, openDetailsPanel }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        subscribe();
        publish();
    }, []);

    const subscribe = () => {
        const result = mqtt.sub(`tele/${topic}/LWT`, `stat/${topic}/POWER`, `stat/${topic}/STATUS8`);
        if (!result) setTimeout(subscribe, 3000)
    }

    const publish = () => {
        mqtt.pub(`cmnd/${topic}/Status`, '8');
        setTimeout(() => publish(), 30000);
    }

    const openDetails = e => {
        dispatch(actions.setCurrentDevice(item));
        openDetailsPanel();
    }

    return (
        <div className="d-flex align-items-center mb-10">
            <div onClick={openDetails}>
                <DeviceImage icon={item.icon} />
            </div>
            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                <a href className="text-dark text-hover-primary mb-1 font-size-lg" onClick={openDetails}>{ item.name }</a>
                <span className="text-muted">
                    <PowerDetails data={mqtt.powerDetails(topic)} openDetails={openDetails}/>
                </span>
            </div>
            {/*{ this.renderOnline() }*/}
            <Signal topic={topic} />
        </div>
    );

};

export default withMqttContext(PowerItem);
