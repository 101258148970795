/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, {useState} from "react";
import {Button} from "react-bootstrap";
import '@radial-color-picker/react-color-picker/dist/react-color-picker.min.css';
import './style.css';
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import { withMqttContext } from 'app/MqttProvider';
import { TimerList } from '../widgets';
import * as actions from '../../../_redux/device/devicesActions';

const TimersContent = ({ mqtt }) => {

    // Products Redux state
    const dispatch = useDispatch();

    // Getting curret state of devices list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.devices }),
        shallowEqual
    );
    const { entity, timer } = currentState;

    if(!entity) return <></>;

    const timers = mqtt.timers(entity.topic);

    const addNewTimer = () => {
        const index = timers.findIndex(e => e && (e.days == '0000000'));
        if(index > 0){
            dispatch(actions.setCurrentTimer(index, null, true));
        }
    }

    return (
        <>
            <div className="navi navi-icon-circle navi-spacer-x-0">
                <TimerList list={timers} topic={entity.topic} />
                <Button variant="primary" size="lg" block onClick={addNewTimer}>
                    Add new timer
                </Button>
            </div>
        </>
    );
}
export default withMqttContext(TimersContent);
