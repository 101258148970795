export function search(obj, k) {
    const res = lookup(obj, k);
    if(res && res.length > 1){
        return res[1];
    }
    return null;
}

export function lookup(obj, k) {
    for (let key in obj) {
  
      let value = obj[key];
      if (k == key) return [k, value];
  
      if (type(value) == "Object") {
        var y = lookup(value, k);
        if (y && y[0] == k) return y;
      }
      if (type(value) == "Array") {
        // for..in doesn't work the way you want on arrays in some browsers
        //
        for (var i = 0; i < value.length; ++i) {
          var x = lookup(value[i], k);
          if (x && x[0] == k) return x;
        }
      }
    }
  
    return null;
  }
  
  
export function type(object) {
    var stringConstructor = "test".constructor;
    var arrayConstructor = [].constructor;
    var objectConstructor = {}.constructor;
  
    if (object === null) {
      return "null";
    } else if (object === undefined) {
      return "undefined";
    } else if (object.constructor === stringConstructor) {
      return "String";
    } else if (object.constructor === arrayConstructor) {
      return "Array";
    } else if (object.constructor === objectConstructor) {
      return "Object";
    } else {
      return "null";
    }
  }