import React, { useState, useEffect } from "react";
import { withMqttContext } from 'app/MqttProvider';
import { Image } from "react-bootstrap";
import { toAbsoluteUrl } from '_metronic/_helpers';
import * as actions from './../../../../_redux/device/devicesActions';
import { useDispatch } from "react-redux";
import { Signal } from "./../components";

const SecurityWidget = ({ mqtt, item, topic }) => {

    const dispatch = useDispatch();

    useEffect(() => subscribe(), []);

    const subscribe = () => {
        const result = mqtt.sub(`tele/${topic}/LWT`);
        if (!result) setTimeout(subscribe, 3000)
    }

    const getStatusIcon = type => {
        const status = item.extra.securityStatus || '';
        if (status.toLowerCase() === 'away' && type === 'away'){
            type = 'away-enabled';
        }
        else if (status.toLowerCase() === 'disarm' && type === 'disarm'){
            type = 'disarm-enabled';
        }
        else if (status.toLowerCase() === 'home' && type === 'home'){
            type = 'home-enabled';
        }
        return toAbsoluteUrl(`/media/dodo-icons/${type}.png`);
    }

    const renderOnline = () => {
        return mqtt.isOnline(topic) ? <span className="label label-xl label-dot label-success" /> : '';
    }

    const renderExtra = () => {
        return (<span className="text-muted">{ item.location }</span>);
    }

    const renderSensor = (sensor) => {
        return (
            <div key={sensor.id} className="d-flex align-items-center mb-10">
                <div className="symbol symbol-40 symbol-light-primary mr-5">
                <span className="symbol-label">
                    <span className="svg-icon svg-icon-lg svg-icon-primary">
                        {/* <SVG className="h-75 align-self-end" src={this.props.item.icon}></SVG> */}
                        <Image src={sensor.icon} rounded width={40} height={40} />
                    </span>
                </span>
                </div>
                <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                    <a href className="text-dark text-hover-primary mb-1 font-size-lg">{ sensor.name }</a>
                    <span className="text-muted">{ sensor.location }</span>
                </div>
            </div>
        );
    }

    const renderSensors = () => {
        return item.sensors.map(e => renderSensor(e))
    }

    const sendSecurityStatus = (notifications, securityStatus) => {
        dispatch(actions.updateSecurityStatus(item.id || item._id, notifications, securityStatus));
        if (item.sensors.length >= 1 && securityStatus !== 'Disarm') {
            item.sensors.forEach((sensor, index) => {
                if (sensor.name !== 'Motion Sensor') {
                    dispatch(actions.updateSecurityStatus(sensor.id, true));
                }
                if (securityStatus === 'Home' && sensor.name === 'Motion Sensor') {
                    dispatch(actions.updateSecurityStatus(sensor.id, false));
                } else {
                    dispatch(actions.updateSecurityStatus(sensor.id, true));
                }
            })
        }
    }

    return (
        <>
            <div className={`card card-custom mb-4`}>
                {/* Header */}
                {/* Body */}
                <div className="card-body pt-12">
                    <div className="d-flex align-items-center mb-10">
                        <div className="symbol symbol-40 symbol-light-primary mr-5">
                            <span className="symbol-label">
                                <span className="svg-icon svg-icon-lg svg-icon-primary">
                                    {/* <SVG className="h-75 align-self-end" src={this.props.item.icon}></SVG> */}
                                    <Image src={item.icon} rounded width={40} height={40} />
                                </span>
                            </span>
                        </div>
                        <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                            <a href className="text-dark text-hover-primary mb-1 font-size-lg">{ item.name }</a>
                            { renderExtra() }
                        </div>
                        <div>
                            <Signal topic={topic} />
                            {/*{ renderOnline() }*/}
                        </div>
                    </div>
                    <div className="text-center mb-10 row mr-4 ml-4">
                        <div className="col-4">
                            <Image
                                onClick={e => sendSecurityStatus(false, 'Disarm')}
                                src={getStatusIcon('disarm')}
                                rounded width={40}
                                height={40}
                                role="button"
                            />
                            <br/>
                            Disarm
                        </div>
                        <div className="col-4">
                            <Image
                                onClick={e => sendSecurityStatus(true, 'Home')}
                                src={getStatusIcon('home')}
                                rounded width={40}
                                height={40}
                                role="button"
                            />
                            <br/>
                            Home
                        </div>
                        <div className="col-4">
                            <Image
                                onClick={e => sendSecurityStatus(true, 'Away')}
                                src={getStatusIcon('away')}
                                rounded width={40}
                                height={40}
                                role="button"
                            />
                            <br/>
                            Away
                        </div>
                    </div>
                    { renderSensors()}
                </div>
            </div>
        </>
    );

};

export default withMqttContext(SecurityWidget);
