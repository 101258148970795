import React from "react";
import SensorItem from "./SensorItem";
import { Card } from 'react-bootstrap';

export function SensorsWidget({ className, list = [] }) {

    const renderItem = item => {
        return <SensorItem key={item.topic} item={item} topic={item.topic} />;
    };

    return (
          <div className={`card card-custom ${className}`}>
                {/* Header */}
                <div className="card-header border-0">
                      <h3 className="card-title font-weight-bolder text-dark">Sensors</h3>
                      <div className="card-toolbar"></div>
                </div>
                {/* Body */}
                <div className="card-body pt-2"> { list.map(renderItem) } </div>
          </div>
    );

}
