import React from "react";
import LightItem from "./LightItem";

export function LightsWidget({ className, list = [], openDetailsPanel }) {

    let panelToggler = null;

    const renderItem = item => {
        return <LightItem key={item.topic} item={item} topic={item.topic} openDetailsPanel={openDetailsPanel} />;
    };


    return (
          <div className={`card card-custom ${className}`}>
                {/* Header */}
                <div className="card-header border-0">
                      <h3 className="card-title font-weight-bolder text-dark">Lights</h3>
                      <div className="card-toolbar"></div>
                </div>
                {/* Body */}
                <div className="card-body pt-2"> { list.map(renderItem) } </div>
          </div>
    );

}
