import React from "react";
import {Image} from "react-bootstrap";

export function DeviceImage({ icon, width = 40, height = 40 }) {
    return(
        <div className="symbol symbol-40 symbol-light-success mr-5">
            <span className="symbol-label">
                <Image src={icon} rounded width={width} height={height} role="button"/>
            </span>
        </div>
    );
}
