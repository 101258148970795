/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, {useState} from "react";
import { Row } from "react-bootstrap";
import { Slider } from '@material-ui/core';
import '@radial-color-picker/react-color-picker/dist/react-color-picker.min.css';
import './style.css';
import { shallowEqual, useSelector } from "react-redux";
import { withMqttContext } from 'app/MqttProvider';
import { search } from '../JsonHelper';
import { HuePicker, CirclePicker, AlphaPicker } from 'react-color';

const RgbContent = ({ mqtt }) => {

    // Getting curret state of devices list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.devices }),
        shallowEqual
    );
    const { entity } = currentState;

    const data = mqtt[`stat/${entity.topic}/STATUS11`]  || {};
    const colorData = `${search(data, 'color') || '0000000000'}`.substring(0,6);
    const dimmerData = search(data, 'dimmer') || 0;
    const speedData = search(data, 'speed') || 0;
    const schemeData = search(data, 'scheme') || 0;
    const tempratureData = search(data, 'ct') || 153;

    const [color, setColor] = useState(colorData);
    const [temprature, setTemprature] = useState(tempratureData);
    const [schema, setSchema] = useState(schemeData);
    const [dimmer, setDimmer] = useState(dimmerData);
    const [speed, setSpeed] = useState(speedData);

    const sendDimmer = val => {
        setDimmer(val);
        mqtt.pub(`cmnd/${entity.topic}/Dimmer`, val.toString());
    }

    const sendSchema = val => {
        setSchema(val);
        mqtt.pub(`cmnd/${entity.topic}/Scheme`, val.toString());
    }

    const sendSpeed = val => {
        setSpeed(val);
        mqtt.pub(`cmnd/${entity.topic}/Speed`, val.toString());
    }

    const sendTemprature = val => {
        setTemprature(val);
        mqtt.pub(`cmnd/${entity.topic}/CT`, val.toString());
    }

    const sendColor = (color) => {
        if (disabled()) return; 
        const hex = color.hex.replace('#', '');
        setColor(hex);
        mqtt.pub(`cmnd/${entity.topic}/Color`, hex);
    };
    const disabled = () => !mqtt.isOnline(entity.topic);

    return (
        <>
            <div className="mb-8" >
                <HuePicker
                    direction={ 'horizontal' || 'vertical' }
                    color={ `#${color}` }
                    onChangeComplete={ sendColor }
                />
            </div>
            <div className="mb-8 ml-10">
                <CirclePicker
                    direction={ 'horizontal' || 'vertical' }
                    color={ `#${color}` }
                    onChangeComplete={ sendColor }
                />
            </div>
            {/*<div className="mb-8">*/}
            {/*    <AlphaPicker direction={ 'horizontal' || 'vertical' } />*/}
            {/*</div>*/}
            <div className='mb-3'>
                <span className="ml-2">Dimmer</span>
                <div className="mr-6 ml-6 mt-1">
                    <Slider value={dimmer} onChange={(event, newValue) => sendDimmer(newValue)} disabled={disabled()} aria-labelledby="continuous-slider" />
                </div>
            </div>
            <div className='mb-3'>
                <Row>
                    <div className="text-left col-6"><span className='ml-2'>Cold</span></div>
                    <div className="text-right col-6"><span className='mr-6'>Warm</span></div>
                    <div className="col-12">
                        <div className="mr-6 ml-6">
                            <Slider
                                value={temprature} onChange={(e, val) => sendTemprature(val)} aria-labelledby="continuous-slider"
                                min={153}
                                max={500}
                                disabled={disabled()}
                            />
                        </div>
                    </div>
                </Row>
            </div>
            <div className='mb-3'>
                <Row>
                    <div className="text-left col-6"><span className='ml-2'>Schema</span></div>
                    <div className="col-12">
                        <div className="mr-6 ml-6">
                            <Slider value={schema} onChange={(e, val) => sendSchema(val)}
                                    step={1}
                                    marks={ [
                                        { value: 0, label: 'OFF' },
                                        { value: 1, label: '1' },
                                        { value: 2, label: '2' },
                                        { value: 3, label: '3' },
                                        { value: 4, label: '4' },
                                    ]}
                                    min={0}
                                    max={4}
                                    disabled={disabled()}
                            />
                        </div>
                    </div>
                </Row>
            </div>
            <div className='mb-3'>
                <Row>
                    <div className="text-left col-6"><span className='ml-2'>Speed</span></div>
                    <div className="col-12">
                        <div className="mr-6 ml-6">
                            <Slider
                                value={speed}
                                onChange={(e, val) => sendSpeed(val)}
                                step={1}
                                min={0}
                                max={20}
                                disabled={disabled()} />
                        </div>
                    </div>
                    <div className="text-left col-6"><span className='ml-2 text-muted font-weight-500'>Min</span></div>
                    <div className="text-right col-6"><span className='mr-6 text-muted font-weight-500'>Max</span></div>
                </Row>
            </div>
        </>
    );
}
export default withMqttContext(RgbContent);
