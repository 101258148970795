import React from 'react';
import { withMqttContext } from 'app/MqttProvider';
import { ButtonGroup, Button, Row } from 'react-bootstrap';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "_metronic/_helpers";
import * as actions from './../../../../_redux/device/devicesActions';
import { useDispatch } from "react-redux";
import moment from "moment";

const DAYS = [
    'S', 'M', 'T', 'W', 'T', 'F', 'S'
];

const TimerItem = ({ mqtt, item, topic, index }) => {
    
    const dispatch = useDispatch();

    if(item.days == '0000000') return <></>;

    const isDaySelected = position => item.days[position] !== '0';
    
    const edit = () => dispatch(actions.setCurrentTimer(index, item, false));

    const renderAction = () => {
        return item.action ? 
            <span className="label label-lg label-light-success label-inline">Command: ON</span>:
            <span className="label label-lg label-light-danger label-inline">Command: OFF</span>;
    }

    const renderTime = () => {
        // Use 12 format.
        const time = moment.utc(item.time, 'HH:mm').local().format('hh:mm A');
        return <span className="text-dark-75 font-weight-bolder d-block font-size-lg">Time: { time }</span>;
    }

    const renderActions = () =>  
            <>
                <button className="btn btn-icon btn-light btn-sm mx-3" onClick={() => edit()}>
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
                    </span>
                </button>
                <button className="btn btn-icon btn-light btn-sm" onClick={() => mqtt.resetTimer(topic, index)}>
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                    </span>
                </button>
            </>;


    return (
        <>
            <div className="d-flex flex-column mb-2">
                <table className="table table-borderless table-vertical-center">
                    <tbody>
                        <tr>
                            <td>
                                {renderTime()}
                            </td>
                            <td className="text-right">
                                {renderActions()}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2'>
                                <div className="d-flex flex-column">
                                    <ButtonGroup size="sm" aria-label="First group">
                                        { DAYS.map((e, i) => <Button key={i} pill="true" variant={isDaySelected(i) ? 'success' : 'secondary'}>{e}</Button>)}
                                    </ButtonGroup>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-0">
                                <FormControlLabel
                                        control={
                                            <Checkbox checked={Boolean(item.arm)} value="arm" />
                                        }
                                        label="Enabled"
                                    />
                            </td>
                            <td className="text-right pr-0 pb-0">
                                <FormControlLabel
                                        control={
                                            <Checkbox checked={Boolean(item.repeat)} value="repeat" />
                                        }
                                        label="Repeat"
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td colspan='2' className="pt-0">
                                {renderAction()}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        <hr />

        </>
    );

};

export default withMqttContext(TimerItem);
