import React from 'react';
import { Badge, OverlayTrigger, Tooltip, Table } from 'react-bootstrap';

const PowerDetails = ({ data, openDetails }) => {

    const { voltage, current, power, reactive, apparent, frequency, factor, today, yesterday, total } = data;

    return (
        <>
            <Badge variant="primary" role="button" onClick={openDetails}>{voltage} V</Badge>{' '}
            <Badge variant="danger" role="button" onClick={openDetails}>{current} A</Badge>{' '}
            <Badge variant="warning" role="button" onClick={openDetails}>{power} W</Badge>{' '}
            <Badge variant="success" role="button" onClick={openDetails}>More</Badge>{' '}
            {/* <OverlayTrigger
                key={'right'}
                placement={'right'}
                overlay={
                    <Tooltip id={`tooltip-right`}>
                        
                        <Table  bordered hover>
                            <tbody>
                                <tr className="text-left">
                                    <td>Apparent Power</td>
                                    <td>{ apparent } VA</td>
                                </tr>
                                <tr className="text-left">
                                    <td>Reactive Power</td>
                                    <td>{ reactive } VAr</td>
                                </tr>
                                <tr className="text-left">
                                    <td>Power Factor</td>
                                    <td>{ factor }</td>
                                </tr>
                                <tr className="text-left">
                                    <td>Frequency</td>
                                    <td>{ frequency } Hz</td>
                                </tr>
                                <tr className="text-left">
                                    <td>Energy Today </td>
                                    <td>{ today } kWh</td>
                                </tr>
                                <tr className="text-left">
                                    <td>Energy Yesterday</td>
                                    <td>{ yesterday } kWh</td>
                                </tr>
                                <tr className="text-left">
                                    <td>Energy Total</td>
                                    <td>{ total } kWh</td>
                                </tr>
                            </tbody>
                        </Table>

                    </Tooltip>
                }
                >
                <Badge variant="secondary">More</Badge>
            </OverlayTrigger> */}
        </>
    );
}

export default PowerDetails;
