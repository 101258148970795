import React, { useEffect } from 'react';
import { withMqttContext } from 'app/MqttProvider';
import { Signal, DeviceImage } from '../components';
import { Image } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import * as actions from './../../../../_redux/device/devicesActions';
import  IOSSwitch from '../components/IOSSwitch';
import { toAbsoluteUrl } from '_metronic/_helpers';

const LightItem = ({ mqtt, item, topic, openDetailsPanel }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        subscribe();
        publish();
    }, []);

    const subscribe = () => {
        const result = mqtt.sub(
            `tele/${topic}/LWT`,
            `stat/${topic}/STATUS8`,
            `stat/${topic}/POWER`,
            `stat/${topic}/RESULT`,
        );
        if (!result) setTimeout(subscribe, 3000)
    };

    const publish = () => {
        mqtt.pub(`cmnd/${topic}/Status`, '8');
        setTimeout(() => publish(), 30000);
    };

    const switchChange = e => {
        mqtt.pub(`cmnd/${topic}/POWER`, e.target.checked ? 'ON' : 'OFF');
    };

    const renderExtra = () => {
        return topic;
    }

    const renderSwitch = () => <IOSSwitch checked={mqtt.isPowerOn(topic)} onChange={switchChange} />

    const isRgb = () => item.data && item.data.type === 'rgb';

    const openDetails = e => {
        mqtt.pub(`cmnd/${topic}/Color`, ' ');
        mqtt.pub(`cmnd/${topic}/Timers`, ' ');
        dispatch(actions.setCurrentDevice(item));
        openDetailsPanel();
    }

    return (
        <div className="d-flex align-items-center mb-10">
            <div onClick={openDetails}>
                <DeviceImage icon={item.icon} />
            </div>
            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                <a href className="text-dark text-hover-primary mb-1 font-size-lg" onClick={openDetails}>
                    { item.name }
                </a>
                <span className="text-muted">
                    <Image src={toAbsoluteUrl(`/media/dodo-icons/paint.png`)} width={25} rounded onClick={openDetails} role="button"/>
                    <Image src={toAbsoluteUrl(`/media/dodo-icons/alarm.png`)} width={25} rounded className="ml-3" onClick={openDetails} role="button"/>
                </span>
                {/*<span className="text-muted">{ renderExtra() }</span>*/}
            </div>
            { renderSwitch() }
            <Signal topic={topic} />
        </div>
    );

};

export default withMqttContext(LightItem);
