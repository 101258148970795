import React from "react";
import TimerItem from "./TimerItem";

export function TimerList({  list = [], topic }) {

    return (
        <>
            {
                list.map((item, index) => <TimerItem key={index} item={item} index={index} topic={topic} />) 
            }
        </>
    );

}
