import React, { useEffect } from 'react';
import { withMqttContext } from 'app/MqttProvider';
import { Signal, DeviceImage } from '../components';
import { search } from '../../JsonHelper';
import { Badge } from 'react-bootstrap';

const SensorItem = ({ mqtt, item, topic }) => {

    useEffect(() => {
        subscribe();
        publish();
    }, []);

    const subscribe = () => {
        const result = mqtt.sub(`tele/${topic}/LWT`, `stat/${topic}/STATUS8`, `stat/${topic}/POWER`);
        if (!result) setTimeout(subscribe, 3000)
    };

    const publish = () => {
        mqtt.pub(`cmnd/${topic}/Status`, '8');
        setTimeout(() => publish(), 30000);
    };

    const renderExtra = () => {
        const data = mqtt[`stat/${topic}/STATUS8`] || '';
        if(!data) return '';

        const level = search(data, 'level');
        if (level) {
            return <Badge variant="primary">{level} L</Badge>;
        }

        const temperature = search(data, 'temperature');
        const humidity = search(data, 'humidity');
        if(temperature && humidity){
            return (
                <>
                    <Badge variant="primary">{temperature} V</Badge>{' '}
                    <Badge variant="primary">{humidity} V</Badge>
                </>
            );
        }

    }

    return (
        <div className="d-flex align-items-center mb-10">
            <DeviceImage icon={item.icon} />
            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                <a href className="text-dark text-hover-primary mb-1 font-size-lg">
                    { item.name }
                </a>
                <span className="text-muted">{ renderExtra() }</span>
            </div>
            <Signal topic={topic} />
        </div>
    );

};

export default withMqttContext(SensorItem);
